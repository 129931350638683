<template>
  <div class="category-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="delay"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :extraParams = "extraParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="createCategory"></v-button>
      </template>
      <template #searchSlot>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionSel2Params" @onChange="onRegionIdChange"></v-select2>
        <v-select label="一级分类" v-model="searchParams.firstLevel" :options="firstOps" @change='getSecondOpsFun'></v-select>
        <v-select label="二级分类" v-model="searchParams.secondLevel" :options="secondOps"></v-select>
        <v-datepicker label="最后操作时间" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="editCategory(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL } from './api'
import { regionParams } from 'common/select2Params'
import { getFirstOpts, getSecondOps } from './../map'

export default {
  name: 'patrolCategoryList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        firstLevel: undefined,
        secondLevel: undefined,
        startTime: '',
        endTime: ''
      },
      extraParams: {},
      levelOps: [{
        text: '全部',
        value: undefined
      }],
      firstOps: [],
      secondOps: [{
        text: '全部',
        value: undefined
      }],
      regionSel2Params: regionParams,
      headers: [
        {
          prop: 'firstName',
          label: '一级分类',
          formatter (row) {
            return row.level === 2 ? row.parentName : row.name
          }
        },
        {
          prop: 'secondName',
          label: '二级分类',
          formatter (row) {
            return row.level === 2 ? row.name : ''
          }
        },
        {
          prop: 'pointCount',
          label: '关联巡更点',
          align: 'right'
        },
        {
          prop: 'userName',
          label: '创建人'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'updateTime',
          label: '最后操作时间'
        }
      ]
    }
  },
  async created () {
    let options = await getFirstOpts()
    this.firstOps = this.levelOps.concat(options)
  },
  mounted () {
    // 检索条件回显联动
    if (this.searchParams.firstLevel) {
      getSecondOps(this.searchParams.firstLevel, this.secondOps)
    }
    if (this.$route.query.routeId) {
      this.extraParams.routeId = this.$route.query.routeId
    }
  },
  methods: {
    getSecondOpsFun (value) {
      this.searchParams.secondLevel = undefined
      this.secondOps = [{
        text: '全部',
        value: undefined
      }]
      getSecondOps(value, this.secondOps)
    },
    createCategory () {
      this.$router.push({
        name: 'patrolCategoryForm'
      })
    },
    editCategory (row) {
      this.$router.push({
        name: 'patrolCategoryForm',
        query: {
          id: row.id
        }
      })
    },
    async onRegionIdChange () {
      let options = await getFirstOpts({ regionId: this.searchParams.regionId })
      this.firstOps = [...this.levelOps, ...options]
    }
  }
}
</script>
