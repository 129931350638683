var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "category-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          exportMethod: "delay",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          extraParams: _vm.extraParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.createCategory },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      on: { onChange: _vm.onRegionIdChange },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.regionSel2Params,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "一级分类", options: _vm.firstOps },
                  on: { change: _vm.getSecondOpsFun },
                  model: {
                    value: _vm.searchParams.firstLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "firstLevel", $$v)
                    },
                    expression: "searchParams.firstLevel",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "二级分类", options: _vm.secondOps },
                  model: {
                    value: _vm.searchParams.secondLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "secondLevel", $$v)
                    },
                    expression: "searchParams.secondLevel",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "最后操作时间",
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.editCategory(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }